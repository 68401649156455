import {
  Component,
  ChangeDetectionStrategy,
  inject,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RouterOutlet,
  RouterLink,
  RouterLinkActive,
  Router,
} from '@angular/router';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { AuthLayoutComponent } from '@app/auth/auth.layout.component';
import { createAccountRoutesConfig } from '@app/create-account/create-account-routes.config';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HotToastService } from '@ngneat/hot-toast';
import { AuthService } from '@app/core/services/client/auth.service';
import { Observable, Subscription } from 'rxjs';
import { SpinDirective } from '@app/shared/directives/spin.directive';
import { Select, Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { AuthActions } from '@app/auth/store/auth.actions';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    AuthLayoutComponent,
    ReactiveFormsModule,
    FormsModule,
    SpinDirective,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnDestroy {
  @Select(AuthSelectors.getLoadingStatus) loading$!: Observable<boolean>;

  fb = inject(FormBuilder);
  authService = inject(AuthService);
  router = inject(Router);
  private _toast = inject(HotToastService);
  private store = inject(Store);

  isPasswordVisible: boolean = false;
  CREATE_ACCOUNT_ROUTES = createAccountRoutesConfig;
  isLoading: boolean = false;
  subs: Subscription[] = [];

  loginForm = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  login() {
    this.store.dispatch(new AuthActions.SetLoadingStatus(true));
    const { email, password } = this.loginForm.value;

    if (!email || !password) {
      this.store.dispatch(new AuthActions.SetLoadingStatus(false));
      this._toast.error('Please enter email and password');
      return;
    }

     this.store.dispatch(new AuthActions.Login({ email, password }));
    // this.store.dispatch(new AuthActions.Sig({ email, password }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
